import { type PaletteColor, palette } from '@/domains/style/models';

type Props = {
    /** チェブロンの背景色 */
    fill?: PaletteColor;
    /** チェブロンのサイズ */
    size?: number;
    /** チェブロンの方向 */
    direction?: 'right' | 'left' | 'up' | 'down';
    /**
     * SVGのCSSクラス名
     *
     * @default ''
     */
    className?: string;
    /**
     * この属性が空の場合は、aria-label属性を付与せず、SVGのrole属性は`presentation`になる。
     * この属性が空でない場合は、aria-label属性の内容として使用され、SVGのrole属性は`img`になる。
     *
     * @default ''
     */
    alt?: string;
};

/**
 * チェブロンの色・方向を指定できる丸形アイコン
 */
export const ChevronCircle: React.FC<Props> = ({
    fill = 'white',
    size = 19,
    direction = 'right',
    className,
    alt = '',
}) => {
    /** SVGの回転を決定する */
    const getRotation = () => {
        switch (direction) {
            case 'right':
                return '0deg';
            case 'left':
                return '180deg';
            case 'up':
                return '-90deg';
            case 'down':
                return '90deg';
            default:
                return '0deg';
        }
    };

    const svgStyle = {
        transform: `rotate(${getRotation()})`,
        width: `${size}px`,
        height: `${size}px`,
    };

    const pathStyle = {
        fill: palette[fill],
    };

    return (
        <svg
            role={alt === '' ? 'presentation' : 'img'}
            aria-label={alt === '' ? undefined : alt}
            viewBox="0 0 512 512"
            style={svgStyle}
            className={className}
        >
            <path
                style={pathStyle}
                d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"
            />
        </svg>
    );
};
