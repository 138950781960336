import React from 'react';
import { concatClassNames } from '@/utils/concatClassNames';
import * as s from './style.css';

/** 汎用的な企業ロゴ */
export const CompanyLogoText: React.FC<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
    const rootClassName = concatClassNames(s.logoText, className);

    return (
        <figure className={rootClassName} {...props}>
            FOCUS
        </figure>
    );
};
