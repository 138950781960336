import React from 'react';
import { concatClassNames } from '@/utils/concatClassNames';
import { ChevronCircle } from '../../Graphics/ChevronCircle/ChevronCircle';
import * as s from './style.css';

type Props = {
    /** 表示するテキスト */
    text: string;
};

/**
 * フッター・ハンバーガーメニュー内部で使用するボタン
 */
export const NavButton: React.FC<
    Props &
        Omit<
            React.DetailedHTMLProps<
                React.ButtonHTMLAttributes<HTMLButtonElement>,
                HTMLButtonElement
            >,
            'children'
        >
> = ({ text, className: propsClassName, type = 'button', ...props }) => {
    const className = concatClassNames(s.buttonBase, propsClassName);

    return (
        <button className={className} type={type} {...props}>
            <div className={s.innerContainer}>{text}</div>
            <div className={s.innerContainer}>
                <ChevronCircle fill="white" size={19} direction="right" />
            </div>
        </button>
    );
};
