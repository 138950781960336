import { useContext } from 'react';

import BreakpointContext from './BreakpointContext';
import type { BreakPointContextValue } from './BreakpointContext';

/**
 * 現在のブレークポイントを取得するためのカスタムフック
 */
export default function useBreakpoint(): BreakPointContextValue {
    const { breakpoint, isMobile, isPc } = useContext(BreakpointContext);

    return { breakpoint, isMobile, isPc };
}
