/** サイトで使用されるページのルートパス */
export const pageRoute = {
    /** ホーム画面 */
    home: '/',
    /** 会社情報 */
    company: '/company',
    /** ニュース */
    news: '/news',
    /** お問い合わせ */
    contact: '/contact',
    /** プライバシーポリシー */
    privacyPolicy: '/privacy-policy',
    /** 投資家向け資料請求フォーム */
    documentRequest: '/document-request',
    /** 404 Not Found */
    notFound: '/404',
    /** LINEでのお問い合わせページ */
    line: 'https://lin.ee/e1BZYKm',
    /** LINEでの友達追加ページ */
    lineAddFriend: 'https://lin.ee/e1BZYKm',
    /** オンライン説明会予約ページ */
    virtualMeeting: 'https://www.focus-ad.world/to/webinar',
} as const;

/** ホーム画面内リンクのID定義 */
export const topPageAnchorId = {
    teamFocustar: 'team-focustar',
    branding: 'branding',
    seo: 'seo',
};

/** ナビゲーションで使用するデータの型定義 */
interface NavLinkDef {
    /** リンク先のURL */
    href: string;
    /** リンクテキスト */
    text: string;
    /** WAI-ARIAラベル */
    ariaLabel: string;
}

/** 共通の上部ナビゲーションで使用されるリンク定義 */
export const globalNavLinks = [
    {
        href: `${pageRoute.home}#${topPageAnchorId.teamFocustar}`,
        text: 'Team FOCUSTAR',
        ariaLabel: 'Team FOCUSTARへのリンク',
    },
    {
        href: `${pageRoute.home}#${topPageAnchorId.branding}`,
        text: 'BRANDING',
        ariaLabel: 'ブランディングへのリンク',
    },
    {
        href: pageRoute.company,
        text: 'COMPANY info',
        ariaLabel: '会社情報へのリンク',
    },
    {
        href: pageRoute.contact,
        text: 'CONTACT',
        ariaLabel: 'お問い合わせへのリンク',
    },
];

/** ホーム画面のヘッダーで使用されるリンク定義 */
export const homeHeaderLinks = [
    {
        href: `${pageRoute.home}#${topPageAnchorId.teamFocustar}`,
        text: 'Team FOCUSTAR',
        ariaLabel: 'Team FOCUSTARへのリンク',
    },
    {
        href: `${pageRoute.home}#${topPageAnchorId.branding}`,
        text: 'BRANDING',
        ariaLabel: 'ブランディングへのリンク',
    },
    {
        href: pageRoute.company,
        text: 'COMPANYinfo',
        ariaLabel: '会社情報へのリンク',
    },
    {
        href: pageRoute.contact,
        text: 'CONTACT',
        ariaLabel: 'お問い合わせへのリンク',
    },
];

/** フッター・ハンバーガーメニューの一番大きなリンクリストのリンク定義 */
export const menuMainLinks = [
    {
        href: `${pageRoute.home}#${topPageAnchorId.teamFocustar}`,
        text: 'Team FOCUSTAR',
        ariaLabel: 'Team FOCUSTARへのリンク',
    },
    {
        href: `${pageRoute.home}#${topPageAnchorId.branding}`,
        text: 'BRANDING',
        ariaLabel: 'ブランディングへのリンク',
    },
    {
        href: `${pageRoute.home}#${topPageAnchorId.seo}`,
        text: 'AI / SEO',
        ariaLabel: 'AI・SEOへのリンク',
    },
    {
        href: pageRoute.company,
        text: 'COMPANY info',
        ariaLabel: '会社情報へのリンク',
    },
    {
        href: pageRoute.news,
        text: 'NEWS',
        ariaLabel: 'ニュースへのリンク',
    },
    {
        href: pageRoute.contact,
        text: 'CONTACT',
        ariaLabel: 'お問い合わせへのリンク',
    },
] as const satisfies NavLinkDef[];

/** フッター・ハンバーガーメニューのボタン上リンクリストのリンク定義 */
export const menuButtonLinks = [
    {
        href: pageRoute.virtualMeeting,
        text: '説明会を予約',
        ariaLabel: '説明会予約ページへのリンク',
    },
    {
        href: pageRoute.line,
        text: 'LINEでお問い合わせ',
        ariaLabel: 'LINEでのお問い合わせへのリンク',
    },
    {
        href: pageRoute.contact,
        text: 'メールフォーム',
        ariaLabel: 'メールフォームへのリンク',
    },
] as const satisfies NavLinkDef[];

/** フッター・ハンバーガーメニューのポリシーリンクリストのリンク定義 */
export const menuPolicyLinks = [
    {
        href: pageRoute.privacyPolicy,
        text: 'プライバシーポリシー',
        ariaLabel: 'プライバシーポリシーへのリンク',
    },
] as const satisfies NavLinkDef[];
