'use client';

import { memo, useCallback, useId, useMemo, useState } from 'react';
import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog';

import { concatClassNames } from '@/utils/concatClassNames';
import * as s from './style.css';

type Props = {
    /**
     *  ダイアログの開閉状態
     * @default false
     */
    open: boolean;
    /**
     * Escキーでダイアログを閉じるかどうかを制御する
     * @default true
     */
    closeOnEscape?: boolean;
    /**
     * ダイアログの開くときの挙動
     * @default 'center'
     */
    transitionFrom?: 'left' | 'right' | 'top' | 'bottom' | 'center';
    /**
     * ダイアログの表示位置
     * @default 'center'
     */
    pos?: 'left' | 'right' | 'top' | 'bottom' | 'center';
    /**
     * 子要素
     * @default ''
     */
    children?: React.ReactNode;
    /**
     * ダイアログに追加するクラス名
     * @default ''
     */
    className?: string;
    /** ダイアログを閉じるためのコールバック関数 */
    onClose?: () => void;
};

/** 汎用的に使うデザインのダイアログ */
export const BasicDialog: React.FC<Props> = memo(
    ({
        closeOnEscape = true,
        transitionFrom = 'center',
        pos = 'center',
        open,
        children,
        className,
        onClose,
    }) => {
        const [isClosing, setIsClosing] = useState(false);
        const id = useId();

        /** ダイアログの中身のクラス名 */
        const contentClassName = useMemo(() => {
            const containerAnimationStyle = isClosing ? s.containerClosing : s.container;

            return concatClassNames(containerAnimationStyle[transitionFrom], className);
        }, [isClosing, transitionFrom, className]);

        /** ダイアログのアニメーションが終了したときのハンドラ */
        const handleAnimationEnd = useCallback(() => {
            if (isClosing) {
                onClose?.();
            }
            setIsClosing(false);
        }, [isClosing, onClose]);

        /** ダイアログを閉じる際のハンドラ */
        const handleClose = useCallback(() => {
            setIsClosing(true);
        }, []);

        /** Escキーを押したときのハンドラ */
        const handleEscapeKeyDown = useCallback(
            (e: KeyboardEvent) => {
                if (closeOnEscape) {
                    handleClose();
                } else {
                    e.preventDefault();
                    return;
                }
            },
            [closeOnEscape, handleClose],
        );

        /** ダイアログの開閉状態が変化した際のハンドラ */
        const handleOpenChange = useCallback(
            (open: boolean) => !open && handleClose(),
            [handleClose],
        );

        return (
            <Root open={open} onOpenChange={handleOpenChange}>
                <Portal>
                    <Overlay className={s.overlayFlex[pos]}>
                        <Content
                            className={contentClassName}
                            data-state={open ? 'open' : 'closed'}
                            // 閉じる際にアニメーションを実行するためのキー
                            key={`dialog-${id}-closing-${isClosing}`}
                            onAnimationEnd={handleAnimationEnd}
                            onEscapeKeyDown={handleEscapeKeyDown}
                            onInteractOutside={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                            onPointerDownOutside={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                        >
                            {children}
                        </Content>
                    </Overlay>
                </Portal>
            </Root>
        );
    },
);

BasicDialog.displayName = 'BasicDialog';
