import React from 'react';
import { memo } from 'react';

type Props = {
    /** タグ名 */
    as?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    /** 子要素 */
    children?: React.ReactNode;
} & React.DetailsHTMLAttributes<HTMLHeadingElement | HTMLDivElement>;

/**
 * HTMLタグを動的に変更するコンポーネント
 */
export const DynamicHeadingElement: React.FC<Props> = memo(
    ({ as: Tag = 'div', children, ...props }) => {
        return <Tag {...props}>{children}</Tag>;
    },
);

DynamicHeadingElement.displayName = 'DynamicHeadingElement';
