import { MainNav } from '@/components/ui/Navigation/MainNav/MainNav';
import { PolicyNav } from '@/components/ui/Navigation/PolicyNav/PolicyNav';
import { concatClassNames } from '@/utils/concatClassNames';
import { CompanyLogoText } from '../../Graphics/CompanyLogoText/CompanyLogoText';
import { Copyright } from '../../Layout/Copyright/Copyright';
import { ButtonNav } from '../ButtonNav/ButtonNav';
import * as s from './style.css';

type Props = {
    /** リンククリック時のコールバック */
    onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/**
 * フッター・ハンバーガーメニュー内部で使用するナビゲーションメニュー
 */
export const NavigationMenu: React.FC<
    Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ onLinkClick, className, ...props }) => {
    const rootClassName = concatClassNames(s.rootContainer, className);

    return (
        <div className={rootClassName} {...props}>
            <div className={s.logoContainer}>
                <CompanyLogoText />
            </div>
            <div className={s.menuContainer}>
                <div>
                    <MainNav onLinkClick={onLinkClick} />
                </div>
                <div>
                    <ButtonNav className={s.buttonNavBox} onLinkClick={onLinkClick} />
                </div>
            </div>
            <div className={s.policyAndCopyRightContainer}>
                <PolicyNav onLinkClick={onLinkClick} />
                <Copyright />
            </div>
        </div>
    );
};
