'use client';

import { useCallback, useEffect, useState } from 'react';

/** スクロールの方向 */
type Direction = 'up' | 'down' | 'stopped';

/** スクロールの状態 */
type ScrollState = 'scrolling' | 'stopped';

type Props = {
    /**
     * スクロールが停止してから状態を切り替えるまでの遅延時間（ミリ秒）
     *
     * @default 3000
     */
    stopDelay?: number;
    /** スクロール上昇時に実行されるコールバック関数 */
    onScrollUp?: () => void;
    /** スクロール下降時に実行されるコールバック関数 */
    onScrollDown?: () => void;
    /**
     * スクロールの初期方向
     *
     * @default 'stopped'
     */
    defaultDirection?: Direction;
    /**
     * スクロールの初期状態
     *
     * @default 'stopped'
     */
    defaultState?: ScrollState;
};

type ReturnValue = {
    /** スクロールの方向 */
    scrollDirection: Direction;
    /** スクロールの状態 */
    scrollState: ScrollState;
};

/**
 * スクロールに基づいて任意の状態を切り替えるためのカスタムHooks。
 * 一定時間スクロールが停止した場合、またはスクロールが上昇した場合に状態を切り替えます。
 */
export const useScrollBasedState = ({
    stopDelay = 3000,
    onScrollUp,
    onScrollDown,
    defaultDirection = 'stopped',
    defaultState = 'stopped',
}: Props): ReturnValue => {
    // スクロールの方向と状態を管理するための状態変数を定義
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | 'stopped'>(
        defaultDirection,
    );
    const [scrollState, setScrollState] = useState<'scrolling' | 'stopped'>(defaultState);
    // 前回のスクロール位置を保持するための状態変数
    const [lastScrollY, setLastScrollY] = useState(0);
    // スクロール停止のタイマーを管理するための状態変数
    const [scrollTimer, setScrollTimer] = useState<number | null>(null);

    /** スクロールが停止した場合の処理 */
    const handleScrollStop = useCallback(() => {
        // スクロール上昇時のコールバックを実行
        onScrollUp?.();
        // スクロールの方向と状態を "stopped" に設定
        setScrollDirection('stopped');
        setScrollState('stopped');
    }, [onScrollUp]);

    /** スクロールイベントのハンドラ */
    const handleScroll = useCallback(() => {
        // 現在のスクロール位置を取得
        const currentScrollY = window.scrollY;

        // 既存のスクロール停止タイマーをクリア
        if (scrollTimer) {
            clearTimeout(scrollTimer);
        }

        // スクロールの方向を判定し、対応するコールバックを実行
        if (currentScrollY < lastScrollY) {
            onScrollUp?.();
            setScrollDirection('up');
        } else if (currentScrollY > lastScrollY) {
            onScrollDown?.();
            setScrollDirection('down');
        }

        // スクロール状態を "scrolling" に設定
        setScrollState('scrolling');
        // 新しいスクロール停止タイマーを設定
        setScrollTimer(window.setTimeout(handleScrollStop, stopDelay));

        // 現在のスクロール位置を保持
        setLastScrollY(currentScrollY);
    }, [lastScrollY, scrollTimer, handleScrollStop, stopDelay, onScrollUp, onScrollDown]);

    useEffect(() => {
        // スクロールイベントのリスナーを追加
        window.addEventListener('scroll', handleScroll);

        // クリーンアップ関数でスクロールイベントのリスナーを削除し、タイマーをクリア
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimer) {
                clearTimeout(scrollTimer);
            }
        };
    }, [handleScroll, scrollTimer]);

    // スクロールの方向と状態を返す
    return { scrollDirection, scrollState };
};
