import Link from 'next/link';
import { menuMainLinks } from '@/configs/navigation';
import * as s from './style.css';

type Props = {
    /** リンククリック時のコールバック */
    onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/** フッター・ハンバーガーメニュー内部で使用するナビゲーション */
export const MainNav: React.FC<
    Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ onLinkClick, ...props }) => (
    <nav {...props}>
        <ul className={s.linkList}>
            {menuMainLinks.map((link, index) => (
                <li key={`${link.href}-${index}`} className={s.linkListItem}>
                    <Link href={link.href} onClick={onLinkClick} aria-label={link.ariaLabel} scroll>
                        {link.text}
                    </Link>
                </li>
            ))}
        </ul>
    </nav>
);
