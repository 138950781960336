import Link from 'next/link';
import { menuButtonLinks } from '@/configs/navigation';
import { NavButton } from '../../Inputs/NavButton/NavButton';
import * as s from './style.css';

type Props = {
    /** リンククリック時のコールバック */
    onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/** フッター・ハンバーガーメニュー内部で使用するボタン型のナビゲーション */
export const ButtonNav: React.FC<
    Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ onLinkClick, ...props }) => (
    <nav {...props}>
        <ul className={s.buttonLinkList}>
            {menuButtonLinks.map((link, index) => (
                <li key={`${link.href}-${index}`}>
                    <Link href={link.href} onClick={onLinkClick} aria-label={link.ariaLabel}>
                        <NavButton text={link.text} />
                    </Link>
                </li>
            ))}
        </ul>
    </nav>
);
