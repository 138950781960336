import React from 'react';
import { concatClassNames } from '@/utils/concatClassNames';
import * as s from './style.css';

type Props = {
    /**
     * Closeボタンとして表示するかどうか
     *
     * @default false
     */
    isCloseButton?: boolean;
};

/**
 * メニューを開くために使用するボタン。
 * CSSで中のアイコンを描画しているので調整時は注意。
 */
export const MenuButton: React.FC<
    Props &
        React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ isCloseButton = false, className, type = 'button', ...props }) => {
    const rootClassName = concatClassNames(s.buttonRoot, className);
    const lineClassNames = {
        first: isCloseButton
            ? s.iconLineVariant.closeButtonFirst
            : s.iconLineVariant.normalButtonFirst,
        second: isCloseButton
            ? s.iconLineVariant.closeButtonSecond
            : s.iconLineVariant.normalButtonSecond,
    };
    const buttonText = isCloseButton ? 'CLOSE' : 'MENU';

    return (
        <button className={rootClassName} type={type} {...props}>
            <span className={s.iconContainer} role="presentation">
                <span className={lineClassNames.first} />
                <span className={lineClassNames.second} />
                <span className={s.text}>{buttonText}</span>
            </span>
        </button>
    );
};
